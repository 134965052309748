import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import videoReducer from './videoSlice';
import assistantReducer from './assistantSlice';
import homeReducer from './homeSlice';
import chatSlice from './chatSlice';
import flashCardSlice from './flashCardSlice';
import maqSlice from './maqSlice';
import mcqSlice from './mcqSlice';

export const store = configureStore({
  reducer: {
    video: videoReducer,
    user: userReducer,
    assistant: assistantReducer,
    home: homeReducer,
    chat: chatSlice,
    flash: flashCardSlice,
    maq: maqSlice,
    mcqSlice
  },
});