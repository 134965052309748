import React, { useEffect, useState } from "react";
import Logo from "../assets/logo_text.svg";
import LogoIcon from "../assets/logo_icon.svg";
import GoogleLogin from "../components/GoogleLogin";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { auth } from "../config/firebaseConfig"; // Import initialized Firebase auth
import SignUpSection from "./SignUpSection";

const LoginPage = () => {
  const { t } = useTranslation(); // Translation hook
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      navigate("/");
    }
  }, [navigate]);

  // const createPlanHandler = async () =>{
  //   await createPlan(planData[2])
  // }

  
  return (
    <div
      className="h-screen flex items-center justify-center bg-cover bg-center "
      style={{
        backgroundImage:
          "url('https://media.razorpay.com/file/platform/frontend-auth/razorpay/razorpay-bg-visual-1.3x.jpeg')",
      }}
    >
      <LeftSection />

      <div className=" p-2 md:py-4 md:pr-4 w-full h-full max-w-lg">
        <div className="flex flex-col md:flex-row rounded-lg shadow-lg overflow-hidden w-full   h-full bg-white/70 backdrop-blur-md">
          <SignUpSection />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;


const LeftSection = () => {
  const { t } = useTranslation();
  return (
    // bg-opacity-90 bg-gradient-to-t from-black/50 via-black/40 to-transparent
    <div className="hidden md:flex flex-col justify-between items-start h-full px-20 py-10">
      {/* <div className="w-[120px] bg-red-300 flex items-center">
        <img src={LogoIcon} alt="Sumrly.ai Logo" className="" />
        <img src={Logo} alt="Sumrly.ai Logo" className=" " />
      </div> */}
      <div>
        <h1 className="text-xl text-white font-semibold">Sumrly AI.</h1>
      </div>
      <div className=" text-white w-full ">
        <div className="h-full  flex justify-center  flex-col px-4 lg:px-0">
          <h1 className="text-4xl font-bold mb-4">{t("welcome.title")}</h1>
          <p className="text-sm mb-6 text-gray-300">
            {t("welcome.description")}
          </p>
          <p className="text-sm mb-6 text-gray-300">
            {t("welcome.additionalInfo")}
          </p>
          <footer className="text-xs mt-auto text-gray-400">
            &copy; 2024 Sumrlyai. {t("welcome.footer")}
          </footer>
        </div>
      </div>
    </div>
  );
};
