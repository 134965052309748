import axios from "axios";
import { API } from "../apikeys";
import { getUserId } from "../utils/utils";

// Create an Axios instance with the user ID set as a default header
const axiosInstance = axios.create({
  baseURL: API,
  headers: {
    "X-User-Id": getUserId(),
  },
});

// Function to add each chunk of the message to the thread
export const addChatMessageToSpace = async (spaceId, message) => {
  try {
    const response = await axiosInstance.post(`/space-chat`, {
      message,
      spaceId,
    });

    if (response.status !== 201) {
      console.error("Error getting response from server", response);
      throw new Error("Failed to add message chunk");
    }

    return response?.data?.response;
  } catch (error) {
    console.error("Error adding message to chat thread:", error);
    throw error;
  }
};

export const fetchSpaceChat = async (spaceId, lastCreatedAt) => {
  try {
    const response = await axiosInstance.get(
      `/space-chat?spaceId=${spaceId}&lastCreatedAt=${lastCreatedAt}&limit=20`
    );

    if (response.status !== 200) {
      console.error("Error getting response from server", response);
      throw new Error("Failed to Fetching Messages");
    }

    return response?.data;
  } catch (error) {
    console.error("Error adding message to chat thread:", error);
    throw error;
  }
};
