import React, { useEffect } from "react";
import { useSearchParams, useParams, useLocation } from "react-router-dom";
import VideoSection from "../components/VideoSection";
import RightSection from "../components/RightSection";
import DividerComponent from "../components/DividerComponent";
import Header from "../components/Header";
import { saveHistory, saveHistoryForPdf } from "../apis/youtubeAPI";
import { useDispatch, useSelector } from "react-redux";
import { useVideoData } from "../hooks/useVideoData";
import { getAllThreadsByVdId } from "../apis/assistantApi";
import Spinner from "../components/Spinner";
import PdfSection from "../components/PdfSection";

const SkeletonSummarySection = () => (
  <div className="mb-6 animate-pulse">
    <div className="h-5 bg-gray-900 rounded w-1/3 mb-3"></div>
    <div className="space-y-2">
      <div className=" bg-gray-900 rounded w-full h-[10vh]"></div>
      <div className="h-4 bg-gray-900 rounded w-5/6"></div>
      <div className="h-4 bg-gray-900 rounded w-3/4"></div>
      <div className=" bg-gray-900 rounded w-full h-[10vh]"></div>
      <div className="h-4 bg-gray-900 rounded w-5/6"></div>
      <div className="h-4 bg-gray-900 rounded w-3/4"></div>
    </div>
  </div>
);

const VideoPage = () => {
  const { videoId } = useParams(); // Extract videoId from route parameters
  const { state } = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  let type = searchParams.get("type");

  const threads = useSelector((state) => state.assistant?.threads);
  let summaryThread = threads?.find((thr) => thr.type === "SUMMARY");
  const { transcript, fetchSummaryData } = useVideoData(videoId);
  const dispatch = useDispatch();
  const assistants = useSelector((state) => state.assistant?.assistants);
  const chatBotAssistant = assistants?.find(
    (assistant) => assistant.type === "SUMMARY"
  );

  useEffect(() => {
    const getMyThreads = async () => {
      await getAllThreadsByVdId(videoId, dispatch);
    };

    getMyThreads()
  }, []);

  useEffect(() => {
    // Function to save video details
    const saveVideoDetails = async () => {
      try {
        // await fetchSummaryData(
        //   summaryThread.threadId,
        //   chatBotAssistant?.assistantId
        // );

        if (type === "PDF") {
          saveHistoryForPdf({
            videoId,
            type,
            pdfUrl: transcript?.s3Url,
            title: state?.fileName,
          });
        } else {
          await saveHistory(videoId, "", type);
        }
      } catch (error) {
        console.error("Error saving video details:", error);
      }
    };

    // Call saveVideoDetails only if videoId is available
    if (
      videoId &&
      transcript
    ) {
      saveVideoDetails();
    }
  }, [videoId, transcript]);

  return (
    <div>
      <Header />
      <>
        {transcript ? (
          <>
            <div className="flex w-full flex-col  lg:flex-row bg-black text-white lg:h-[92vh] py-2 space-y-6 lg:space-y-0">
              {type === "YOUTUBE" && <VideoSection />}
              {type === "PDF" && <PdfSection />}

              <DividerComponent />
              {transcript ? (
                <RightSection />
              ) : (
                <div className="bg-black border w-full lg:w-[50%] border-gray-700 py-2 px-4 rounded-lg  h-[88vh]">
                  {SkeletonSummarySection()}
                  <br />
                  {SkeletonSummarySection()}
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="w-full h-[92vh] bg-black flex items-center justify-center">
            <Spinner />
          </div>
        )}
      </>
    </div>
  );
};

export default VideoPage;
