import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home";
import VideoPage from "./pages/VideoPage";
import LoginPage from "./pages/LoginPage";
import ProtectedRoute from "./components/ProtectedRoute";
import { Provider, useDispatch } from "react-redux";
import { store } from "./redux/store"; // import store
import HistoryPage from "./pages/HistoryPage";
import Pricing from "./pages/Pricing";
import PaymentPage from "./pages/PaymentPage";
import SuccessPage from "./pages/SuccessPage";
import PaymentCancelPage from "./pages/PaymentCancelPage";
import { useEffect, useState } from "react";
import { checkPlanExpiry } from "./apis/planAPI";
import Space from "./pages/Space";
import Settings from "./pages/Settings";
import LanguageEditor from "./pages/LanguageEditor";
import i18n from "./i18n"; // Adjust the path based on your file structure
import axios from "axios";
import Spinner from "./components/Spinner";
import { getTranslations } from "./apis/translationApi";
import PromptManager from "./pages/PromptManager";
function App() {
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    const initializeApp = async () => {
      try {
        // Perform API calls or initialization logic here
        await Promise.all([fetchTranslations(), checkPlanExpiry()]);
        console.log("Initialization completed successfully");
      } catch (error) {
        console.error("Error during initialization:", error);
      } finally {
        setLoading(false); // Set loading to false after initialization
      }
    };

    initializeApp();
  }, []);

  const fetchTranslations = async () => {
    const languages = ["fr"];
    for (const lang of languages) {
      try {
        const response = await getTranslations(lang);
        i18n.addResourceBundle(lang, "translation", response, true, true);
      } catch (error) {
        console.error(`Failed to fetch translations for ${lang}:`, error);
      }
    }
  };

  if (loading) {
    // Show loading spinner while initialization is in progress
    return (
      <div className="bg-customGray flex items-center justify-center h-screen">
        <Spinner size="large" tip="Loading app, please wait..." />
      </div>
    );
  }

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/* Login Route */}
          <Route path="/login" element={<LoginPage />} />

          {/* Protected Routes */}

          <Route
            path="/history"
            element={
              <ProtectedRoute>
                <HistoryPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/space/:spaceId"
            element={
              <ProtectedRoute>
                <Space />
              </ProtectedRoute>
            }
          />

          <Route
            path="/cancel"
            element={
              <ProtectedRoute>
                <PaymentCancelPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/success"
            element={
              <ProtectedRoute>
                <SuccessPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <HomePage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/payment"
            element={
              <ProtectedRoute>
                <PaymentPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/pricing"
            element={
              <ProtectedRoute>
                <Pricing />
              </ProtectedRoute>
            }
          />

          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />

          <Route
            path="/history"
            element={
              <ProtectedRoute>
                <HistoryPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/video/:videoId"
            element={
              <ProtectedRoute>
                <VideoPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/localization"
            element={
              <ProtectedRoute>
                <LanguageEditor />
              </ProtectedRoute>
            }
          />

          <Route
            path="/prompt-manager"
            element={
              <ProtectedRoute>
                <PromptManager />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
