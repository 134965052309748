import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, message, Checkbox, Button } from "antd";
import Sidebar from "./Sidebar";
import LanguageDropdown from "./LanguageDropdown";
import logo_icon from "../assets/logo_icon.svg";
import { useTranslation } from "react-i18next";
import { fetchAllSpaces as fetchSpacesAPI } from "../apis/spaceAPI";
import { saveHistory } from "../apis/youtubeAPI";
import { isMaxPremium } from "../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { setAssistants } from "../redux/assistantSlice";
import { getAllAssistants, getAllThreads, getAllThreadsByVdId } from "../apis/assistantApi";

const MySpaceIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-box flex-shrink-0 h-5 w-5"
  >
    <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path>
    <path d="m3.3 7 8.7 5 8.7-5"></path>
    <path d="M12 22V12"></path>
  </svg>
);

const Header = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const spacesFromStore = useSelector((state) => state.home?.spaces);
  const [spaces, setSpaces] = useState(spacesFromStore);
  const [selectedSpaces, setSelectedSpaces] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { videoId } = useParams();
  const assistants = useSelector((state) => state.assistant?.assistants);

  const dispatch = useDispatch();
  // Toggle Sidebar Visibility
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const getThredas = async () => {
     ///set all threads
    getAllThreadsByVdId(videoId, dispatch);
    };
    if (videoId) {
      // getThredas();

      
    }
  }, [videoId]);

  useEffect(() => {
    const getAllAssitantByVdId = async () => {
      try {
        let res = await getAllAssistants();
        dispatch(setAssistants(res));
      } catch (error) {
        console.error("Error getAllThreads details:", error);
      }
    };

    if (assistants?.length === 0) {
      getAllAssitantByVdId();
    }


  }, [videoId]);

  const handleSpaceSelection = (spaceId) => {
    setSelectedSpaces((prevSelected) =>
      prevSelected.includes(spaceId)
        ? prevSelected.filter((id) => id !== spaceId)
        : [...prevSelected, spaceId]
    );
  };

  const handleAddToSpace = async () => {
    try {
      for (const spaceId of selectedSpaces) {
        await saveHistory(videoId, spaceId);
      }
      setSpaces((prevSpaces) =>
        prevSpaces.filter((space) => !selectedSpaces.includes(space.id))
      );
      setSelectedSpaces([]);
      message.success("Added to selected spaces successfully!");
    } catch (error) {
      message.error("Failed to add to some spaces.");
    } finally {
      setDropdownVisible(false);
    }
  };

  const filteredSpaces =
    spaces && spaces.length > 0
      ? spaces.filter((space) =>
          space.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : [];

  const dropdownMenu = (
    <div className="p-4 bg-black border border-gray-700 rounded-lg shadow-lg w-72">
      <div className="relative">
        <input
          placeholder={t("space.searchSpace")}
          className="w-full bg-black border border-gray-700 text-white placeholder-gray-400 rounded-lg py-2 px-2 pl-6 focus:outline-none focus:ring-2 focus:ring-gray-800 transition duration-300"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {/* Blinking cursor effect */}
        <span className="absolute right-3 top-1/2 transform -translate-y-1/2 animate-blink">
          |
        </span>
      </div>

      <div className="max-h-48 overflow-y-auto">
        {filteredSpaces.length > 0 ? (
          filteredSpaces.map((space) => (
            <div key={space.id} className="flex items-center my-2  mt-4">
              <Checkbox
                checked={selectedSpaces.includes(space.id)}
                onChange={() => handleSpaceSelection(space.id)}
              />
              <span className="ml-2 text-white">{space.name}</span>
            </div>
          ))
        ) : (
          <p className="text-gray-400 text-center mt-6">{t("space.NoSpace")}</p>
        )}
      </div>
      <Button
        onClick={selectedSpaces.length === 0 ? () => {} : handleAddToSpace}
        className={`w-full mt-4 ${
          selectedSpaces.length === 0 ? "bg-gray-400" : "bg-gray-800"
        } text-white border-gray-600`}
      >
        {t("space.addToSpace")}
      </Button>
    </div>
  );

  // Function to determine if all plans should be disabled
  const disableAllPlans = () => {
    // Disable all plans if active plan's price matches the max price
    return false;
  };

  return (
    <>
      <header className="flex justify-between items-center px-4 bg-black text-white h-[8vh] shadow-md border-b border-gray-800">
        <div className="flex items-center space-x-3 cursor-pointer">
          <button onClick={toggleSidebar} className=" text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>

        <div className="cursor-pointer" onClick={() => navigate("/")}>
          <img
            src={logo_icon}
            className="hidden lg:block h-[80px] ml-20 cursor-pointer"
            alt="Logo Icon"
          />
        </div>

        <div className="lg:flex items-center space-x-4">
          {videoId && (
            <Dropdown
              overlay={dropdownMenu}
              trigger={["click"]}
              visible={dropdownVisible}
              onVisibleChange={(flag) => setDropdownVisible(flag)}
            >
              <button className="ml-4 p-2 bg-gray-800 text-white rounded-lg">
                <MySpaceIcon />
              </button>
            </Dropdown>
          )}
          <LanguageDropdown />
          {disableAllPlans() ? (
            <div className="px-4 py-2 " />
          ) : (
            <button
              className="relative text-white border border-customPink rounded-lg px-4 py-2 font-semibold
             transition duration-300 ease-in-out bg-black 
              hover:bg-customPink hover:text-black shadow-[0px_0px_8px_rgba(34,197,94,0.5)]"
              onClick={() => navigate("/pricing")}
            >
              <span className="absolute inset-0 bg-customPink opacity-20 rounded-lg blur-lg"></span>
              <span className="relative z-10">{t("header.upgrade")}</span>
            </button>
          )}
        </div>
      </header>
      <Sidebar isVisible={isSidebarOpen} onClose={toggleSidebar} />
    </>
  );
};

export default Header;
