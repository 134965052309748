import axios from "axios";
import { API } from "../apikeys";

// Helper function to get the user ID from local storage
const getUserId = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.user?.id; // Ensure you're accessing the correct property for the ID
};

// Create an Axios instance
const axiosInstance = axios.create();

// Add a request interceptor to set the header dynamically
axiosInstance.interceptors.request.use(
  (config) => {
    const userId = getUserId();
    if (userId) {
      config.headers["X-User-Id"] = userId; // Set the user ID from local storage as a header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Fetch all prompts
export const fetchAllPrompts = async () => {
  try {
    const response = await axiosInstance.get(`${API}/prompts`);
    return response.data;
  } catch (error) {
    console.error("Error fetching prompts:", error);
    throw error;
  }
};

// Fetch a prompt by type
export const fetchPromptByType = async (type) => {
  try {
    const response = await axiosInstance.get(`${API}/prompts/${type}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching prompt by type:", error);
    throw error;
  }
};

// Create or update a prompt
export const savePromptToDb = async ({type, prompt}) => {
  try {
    const response = await axiosInstance.post(`${API}/prompts`, {
      type,
      prompt,
    });
    return response.data;
  } catch (error) {
    console.error("Error saving prompt:", error);
    throw error;
  }
};

// Delete a prompt by type
export const deletePromptByType = async (type) => {
  try {
    const response = await axiosInstance.delete(`${API}/prompts/${type}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting prompt:", error);
    throw error;
  }
};

// Check if a prompt type exists
export const checkPromptExists = async (type) => {
  try {
    const response = await axiosInstance.get(`${API}/prompts/exists/${type}`);
    return response.data;
  } catch (error) {
    console.error("Error checking if prompt exists:", error);
    throw error;
  }
};