import React, { useEffect, useState } from "react";
import { generateMAQ } from "../apis/mostAskQuesApi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import reply_icon from "../assets/reply_icon.svg";
import { addChatMessageToThread, runThreadForMaq } from "../apis/assistantApi";
import { setMAQ } from "../redux/maqSlice";

// Card component representing each question
const QuestionCard = ({ question, onCardClick }) => {
  return (
    <div
      onClick={() => onCardClick(question.question || question?.text)}
      className="min-w-[150px] sm:min-w-[180px] max-w-[220px] sm:max-w-[250px] p-2 bg-black text-white border border-gray-800 rounded-lg mr-2 sm:mr-4 cursor-pointer hover:bg-gray-800 transition-all flex-shrink-0"
    >
      <div className="flex items-start">
        <img src={reply_icon} className="h-8 w-8 mr-2" />
        <p className="text-xs sm:text-sm font-light line-clamp-2 overflow-hidden">
          {question?.question || question?.text}
        </p>
      </div>
    </div>
  );
};

// Main component with horizontal scrollable container
const QuestionCardList = ({ onCardClick }) => {
  const { videoId } = useParams();
  const transcript = useSelector(
    (state) => state.video?.transcript?.transcript
  );

  const maqData = useSelector((state) => state.maq?.maqData);

  const [questions, setQuestions] = useState(maqData);

  const assistants = useSelector((state) => state.assistant?.assistants);
  const chatBotAssistant = assistants?.find(
    (assistant) => assistant.type === "CHAT_PROMPTS"
  );

  const dispatch = useDispatch();

  const threads = useSelector((state) => state.assistant?.threads);
  let maqThread = threads?.find((thr) => thr.type === "CHAT_PROMPTS");

  useEffect(() => {
    if (transcript) {
      const fetchMaq = async () => {
        try {
          const res = await runThreadForMaq(
            maqThread?.threadId,
            videoId,
            chatBotAssistant?.assistantId,
            transcript
          );

          // await generateFlashCard({ transcript, videoId });
          if (res?.status === "completed") {
            if (res.error) {
              return;
            }

            setQuestions(res?.questions);
            dispatch(setMAQ(res?.questions));
          } else {
            fetchMaq();
          }
        } catch (error) {
          console.error("Error generating chapters:", error);
        } finally {
        }
      };

      if (maqData?.length === 0) {
        fetchMaq();
      }
    }
  }, [transcript, chatBotAssistant]);

  if (!questions || questions?.length === 0) return <></>;

  return (
    <div className="overflow-x-auto px-2 py-2 bg-black">
      <div className="flex ">
        {questions?.map((question, index) => (
          <QuestionCard
            key={index}
            question={question}
            onCardClick={onCardClick}
          />
        ))}
      </div>
    </div>
  );
};

export default QuestionCardList;
