import axios from "axios";
import { getUserId } from "../utils/utils";
import { API } from "../apikeys";

// Create an Axios instance with the user ID set as a default header
const axiosInstance = axios.create({
  baseURL: API,
  headers: {
    "X-User-Id": getUserId(), // Set the user ID from local storage
  },
});

// Save a new message to thread
export const saveTranslations = async (lang, data) => {
  try {
    const response = await axiosInstance.post(
      `/translations/${lang}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getTranslations = async (lang) => {
  try {
    const response = await axiosInstance.get(`/translations/${lang}`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
