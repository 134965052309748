import axios from "axios";
import { API } from "../apikeys";
import { getUserId } from "../utils/utils";
import { setThreads } from "../redux/assistantSlice";
import { clearFlashCard } from "../redux/flashCardSlice";
import { clearChatMessages } from "../redux/chatSlice";
import { clearMAQ } from "../redux/maqSlice";
import { clearVideoData } from "../redux/videoSlice";

// Create an Axios instance with the user ID set as a default header
const axiosInstance = axios.create({
  baseURL: API,
  headers: {
    "X-User-Id": getUserId(), // Set the user ID from local storage
  },
});

// Save a new message to thread
export const addMessageToThread = async (threadId, data) => {
  try {
    const response = await axiosInstance.post(
      `/assistant/thread/${threadId}/message`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// create new thread
export const createNewThread = async (data) => {
  // data- >videoId,type
  try {
    const response = await axiosInstance.post(`/assistant/thread`, {
      assistantId: data?.assistantId,
      ...data,
    });
    return response.data?.threadId;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// run thread
export const runThread = async (threadId, videoId, assistantId, transcript) => {
  try {
    const response = await axiosInstance.post(
      `/assistant/thread/${threadId}/run/${assistantId}`,
      { videoId, transcript }
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const fetchSummaryPolling = async (videoId) => {
  try {
    const response = await axiosInstance.get(`/summarize/${videoId}`);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// Get all threads
export const getAllThreads = async (videoId) => {
  try {
    const response = await axiosInstance.get(`/assistant/thread/${videoId}`);
    return response.data?.threads;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

// Get all threads
export const getAllAssistants = async (videoId) => {
  try {
    const response = await axiosInstance.get(`/assistant`);
    return response.data?.assistants;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getAllThreadsByVdId = async (videoId, dispatch) => {
  try {
    let res = await getAllThreads(videoId);

    dispatch(setThreads(res));
    return res;
  } catch (error) {
    console.error("Error getAllThreads details:", error);
  }
};

export const clearRedux = (dispatch) => {
  dispatch(clearFlashCard());
  dispatch(clearChatMessages());
  dispatch(clearMAQ());
  dispatch(clearVideoData());
};

// assistantApi.js

// Creates a new thread for the chatbot if one doesn't exist
export const createChatThread = async (videoId, assistantId, type) => {
  try {
    const response = await axiosInstance.post(`/assistant/thread`, {
      videoId,
      type,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating chat thread:", error);
    throw error;
  }
};

// Function to split the text into chunks of up to 25k characters
const splitTextIntoChunks = (text, chunkSize = 25000) => {
  const chunks = [];
  let start = 0;

  while (start < text.length) {
    chunks.push(text.slice(start, start + chunkSize));
    start += chunkSize;
  }

  return chunks;
};

// Function to add each chunk of the message to the thread
export const addChatMessageToThread = async (threadId, data) => {
  if (!threadId || !data) return null;

  try {
    // Split content if it exceeds the chunk limit
    const chunks = splitTextIntoChunks(data?.transcript, 25000);

    // Sequentially add each chunk as a message
    for (let i = 0; i < chunks.length; i++) {
      const response = await axiosInstance.post(
        `/assistant/thread/${threadId}/message`,
        { ...data, content: chunks[i] }
      );

      if (response.status !== 200) {
        console.error("Error adding chunk to chat thread:", response);
        throw new Error("Failed to add message chunk");
      }

      // If it's the last chunk, return the response
      if (i === chunks.length - 1) return response.data;
    }
  } catch (error) {
    console.error("Error adding message to chat thread:", error);
    throw error;
  }
};

// Run the chatbot thread and poll for response
export const runChatThread = async (threadId, videoId, assistantId) => {
  if (!threadId) return null;
  try {
    const response = await axiosInstance.post(
      `/assistant/thread/${threadId}/run/${assistantId}`,
      { videoId }
    );
    return response.data;
  } catch (error) {
    console.error("Error running chat thread:", error);
    throw error;
  }
};

// Example client-side function
export const runThreadForChat = async (
  threadId,
  videoId,
  assistantId,
  userMessage,
  messageId
) => {
  if (!threadId) return null;
  try {
    const response = await axiosInstance.post(
      `/assistant/thread/${threadId}/chatRun/${assistantId}`,
      { videoId, userMessage, messageId }
    );
    return response.data;
  } catch (error) {
    console.error("Error running chat thread:", error);
    throw error;
  }
};

export const runThreadForFlashCard = async (
  threadId,
  videoId,
  assistantId,
  userMessage,
  messageId
) => {
  try {
    const response = await axiosInstance.post(
      `/assistant/thread/${threadId}/flashcardRun/${assistantId}`,
      { videoId, userMessage, messageId }
    );
    return response.data;
  } catch (error) {
    console.error("Error running chat thread:", error);
    throw error;
  }
};

export const runThreadForChapters = async (
  threadId,
  videoId,
  assistantId,
  transcript
) => {
  try {
    const response = await axiosInstance.post(
      `/assistant/thread/${threadId}/chaptersRun/${assistantId}`,
      { videoId, transcript }
    );
    return response.data;
  } catch (error) {
    console.error("Error running chaptersRun thread:", error);
    throw error;
  }
};

export const runThreadForMaq = async (
  threadId,
  videoId,
  assistantId,
  transcript
) => {
  try {
    const response = await axiosInstance.post(
      `/assistant/thread/${threadId}/maqRun/${assistantId}`,
      { videoId, transcript }
    );
    return response.data;
  } catch (error) {
    console.error("Error running runThreadForMaq thread:", error);
    throw error;
  }
};

export const runThreadForQuizes = async (
  threadId,
  videoId,
  assistantId,
  transcript,
  questionCount
) => {
  try {
    const response = await axiosInstance.post(
      `/assistant/thread/${threadId}/mcqRun/${assistantId}`,
      { videoId, transcript, numbOfQue: questionCount }
    );
    return response.data;
  } catch (error) {
    console.error("Error running runThreadForQuizes thread:", error);
    throw error;
  }
};

export const fetchChapterByID = async (videoId) => {
  if (!videoId) return null;
  try {
    const response = await axiosInstance.get(`/assistant/chapter/${videoId}`);
    return response.data;
  } catch (error) {
    console.error("Error running chaptersRun thread:", error);
    throw error;
  }
};

export const attachMsgToSpace = async (spaceId, transcript) => {
  let res = await getAllThreads(spaceId);

  await addChatMessageToThread(res[0]?.threadId, {
    transcript: transcript,
    videoId: spaceId,
  });
};
