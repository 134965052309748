import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Tabs, Input, Button, message } from 'antd';
import { getTranslations, saveTranslations } from '../apis/translationApi';

const { TabPane } = Tabs;

const LanguageEditor = () => {
  const [data, setData] = useState({}); // Holds the translations for the active language
  const [activeLang, setActiveLang] = useState('fr'); // Default active language is 'fr'
  const [loading, setLoading] = useState(false); // Tracks if data is being fetched

  // Fetch translations for the default language on initial render
  useEffect(() => {
    fetchTranslations('fr'); // Call the API for 'fr' when the component mounts
  }, []);

  // Fetch translations for the selected language
  const fetchTranslations = async (lang) => {
    setLoading(true);
    try {
      const response = await getTranslations(lang)
      setData(response);
      setActiveLang(lang); // Update the active language
      setLoading(false);
    } catch (error) {
      message.error(`Failed to fetch translations for ${lang}.`);
      setLoading(false);
    }
  };

  // Handle input change for nested fields
  const handleInputChange = (path, value) => {
    const updateNestedValue = (obj, path, value) => {
      const keys = path.split('.');
      const lastKey = keys.pop();
      const nested = keys.reduce((acc, key) => {
        if (!acc[key]) acc[key] = {};
        return acc[key];
      }, obj);
      nested[lastKey] = value;
      return { ...obj };
    };

    setData((prev) => updateNestedValue({ ...prev }, path, value));
  };

  // Render editable fields recursively
  const renderEditableFields = (langData, path = '') => {
    return Object.entries(langData).map(([key, value]) => {
      const currentPath = path ? `${path}.${key}` : key;

      if (typeof value === 'object') {
        return (
          <div key={currentPath}>
            <h3>{key}</h3>
            <div style={{ marginLeft: 20 }}>{renderEditableFields(value, currentPath)}</div>
          </div>
        );
      }

      return (
        <div key={currentPath} style={{ marginBottom: 10 }}>
          <span>{key}:</span>
          <Input
            value={value}
            onChange={(e) => handleInputChange(currentPath, e.target.value)}
          />
        </div>
      );
    });
  };

  // Save updated translations to the backend
  const handleSave = async () => {
    try {
      
      await saveTranslations(activeLang,data)

      message.success('Translations updated successfully!');
    } catch (error) {
      message.error('Failed to save translations.');
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <Tabs
        defaultActiveKey="fr" // Set 'fr' as the default selected tab
        onChange={fetchTranslations} // Fetch data when a tab is clicked
      >
        {['en', 'hi', 'fr'].map((lang) => (
          <TabPane tab={lang.toUpperCase()} key={lang}>
            {loading ? (
              <p>Loading translations...</p>
            ) : data && Object.keys(data).length > 0 ? (
              <>
                {renderEditableFields(data)}
                <Button
                  onClick={handleSave}
                  type="primary"
                  style={{ marginTop: 20 }}
                >
                  Save Changes
                </Button>
              </>
            ) : (
              <p>No translations available.</p>
            )}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default LanguageEditor;