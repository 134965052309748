import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mcqData: {
    startScreen:true
  },
};

const mcqSlice = createSlice({
  name: "mcq",
  initialState,
  reducers: {
    setMcqComponentData(state, action) {
      state.mcqData = action.payload;
    },
    clearMCQ(state, action) {
      state.mcqData = []
    },
  },
});

export const { setMcqComponentData,clearMCQ } = mcqSlice.actions;

export default mcqSlice.reducer;