import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UpgradeModal from "./UpgradeModal";
import { fetchChapters } from "../apis/youtubeAPI";
import { setChapters } from "../redux/videoSlice";
import { secondsToMinutes } from "../utils/utils";
import { useVideoData } from "../hooks/useVideoData";
import {
  addChatMessageToThread,
  fetchChapterByID,
  runThreadForChapters,
} from "../apis/assistantApi";
import { SkeletonChapter } from "../skeleton/SkeletonChapter";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { ExportOutlined } from "@ant-design/icons";

const ChaptersTab = ({ playerRef, height }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();

  let type = searchParams.get("type");

  const { videoId } = useParams();
  const navigate = useNavigate();
  const transcript = useSelector(
    (state) => state.video?.transcript?.transcript
  );

  

  const chaptersFromStore = useSelector((state) => state.video?.chapters);

  const [chapters, setChaptersState] = useState(chaptersFromStore || null);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const assistants = useSelector((state) => state.assistant?.assistants);
  const threads = useSelector((state) => state.assistant?.threads);

  const chatBotAssistant = assistants?.find(
    (assistant) => assistant.type === "CHAPTERS"
  );
  const chapterThread = threads?.find((thr) => thr?.type === "CHAPTERS");

  useEffect(() => {
    const loadChapters = async () => {
      setLoading(true);
      try {
        const fetchedChapters = await fetchChapters(videoId);
        if (fetchedChapters?.chapters) {
          dispatch(setChapters(fetchedChapters?.chapters));
          setChaptersState(fetchedChapters?.chapters);
        }
      } catch (error) {
        setPopupMessage(t("chaptersTab.fetchError"));
        setShowPopup(true);
      } finally {
        setLoading(false);
      }
    };

    if (transcript) loadChapters();
  }, [transcript]);

  const handleChapterClick = (startTime) => {
    if (playerRef.current) {
      const seconds = parseInt(startTime, 10);
      playerRef.current.contentWindow.postMessage(
        JSON.stringify({
          event: "command",
          func: "seekTo",
          args: [seconds, true],
        }),
        "*"
      );
    }
  };

  const handleGenerateChapters = async () => {
    setLoading(true);
  
    try {
      const res = await runThreadForChapters(
        chapterThread?.threadId,
        videoId,
        chatBotAssistant?.assistantId,
        transcript
      );
  
      // If the response status is "completed"
      if (res?.status === "completed") {
        // If there's an error in the response, show an error popup and exit
        if (res.error) {
          setPopupMessage(t("chaptersTab.limitError"));
          setShowPopup(true);
          return;
        }
        // Dispatch and set chapters only if no error is present
        dispatch(setChapters(res.chapters));
        setChaptersState(res.chapters);
        setLoading(false);
      }else{
        handleGenerateChapters()
      }
    } catch (error) {
      // Handle any errors that occur during the request
      setPopupMessage(t("chaptersTab.limitError"));
      setShowPopup(true);
    } finally {
      // Ensure loading state is reset regardless of the outcome
      
    }
  };
  
  const renderChapters = () => {
    if ((!chapters || chapters?.length === 0) && !loading) {
      return (
        <button
          onClick={handleGenerateChapters}
          className="flex items-center space-x-2 bg-gray-700 p-2 rounded-lg text-white w-full justify-center"
        >
          ✚ {t("chaptersTab.generate")}
        </button>
      );
    }

    return chapters?.map((chapter, index) => (
      <div
      key={index}
      className="group py-6 px-4 hover:bg-customGray rounded-lg cursor-pointer transition duration-200"
      onClick={() => handleChapterClick(chapter.start_time)}
    >
      <div className="mb-2">
        {type === "YOUTUBE" && chapter?.start_time >= 0&& (
          <span className="text-sm text-gray-400 border-b-0 group-hover:border-b group-hover:border-gray-400 transition duration-200">
            {secondsToMinutes(chapter.start_time)} 
          </span>
        )}
        <h3 className="text-lg font-semibold text-gray-100">
          {chapter.title}
        </h3>
      </div>
      <p className="text-sm text-gray-300">
        {chapter?.summary || chapter?.content}
      </p>
    </div>
    
    ));
  };

  const handlePopupClose = () => setShowPopup(false);

  const handleUpgradeClick = () => {
    setShowPopup(false);
    navigate("/pricing");
  };

  // New function to download PDF
  const handleDownloadPDF = async () => {
    const pdf = new jsPDF();
    const chapterElement = document.getElementById("chapter-content");

    await html2canvas(chapterElement, {
      backgroundColor: "#FFFFFF", // Ensures a white background
      scale: 2, // Higher resolution for better quality
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      // Add title manually
      pdf.text("Chapters", 10, 10);

      pdf.addImage(imgData, "PNG", 10, 20, pdfWidth - 20, pdfHeight - 30); // Adding padding
      pdf.save("chapters.pdf");
    });
  };

  return (
    <>
      <div className="relative">
        {/* Export icon positioned in the top-right corner of the container */}
        {chapters?.length > 0 && (
          <ExportOutlined
            onClick={handleDownloadPDF}
            style={{
              fontSize: "16px",
              color: "gray",
              cursor: "pointer",
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          />
        )}
        {/* Hidden title for the PDF */}
        <h1 id="pdf-title" style={{ display: "none" }}>
          Chapters
        </h1>
        {loading ? (
          <div
            id="chapter-content"
            className={`chapter-content overflow-y-auto ${
              height || "h-[28vh]"
            } p-4 bg-black text-white rounded-lg space-y-4`}
          >
            <SkeletonChapter />
            <SkeletonChapter />
            <SkeletonChapter />
          </div>
        ) : (
          <div
            id="chapter-content"
            className={`chapter-content overflow-y-auto ${
              height || "h-[28vh]"
            } p-4 bg-black text-white rounded-lg`}
          >
            {renderChapters()}
          </div>
        )}
        {showPopup && (
          <UpgradeModal
            message={popupMessage}
            onClose={handlePopupClose}
            visible={showPopup}
            onUpgradeClick={handleUpgradeClick}
          />
        )}
      </div>
    </>
  );
};

export default ChaptersTab;
