import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import SubscriptionPlans from "../components/SubscriptionPlans";
import { message, Spin } from "antd";
import HistoryPage from "./HistoryPage";
import YouTubeInputField from "../components/YouTubeInputField";
import { createSpace, fetchAllSpaces } from "../apis/spaceAPI";
import { useTranslation } from "react-i18next"; // Import the translation hook
import ExploreTopics from "../components/ExploreTopics";
import UpgradeModal from "../components/UpgradeModal";
import SkeletonDashboard from "../components/SkeletonDashboard";
import { useDispatch, useSelector } from "react-redux";
import { setSpaces } from "../redux/homeSlice";
import Spinner from "../components/Spinner";
import DeleteSpaceModal from "../components/delete/DeleteSpaceModal";
import { setMcqComponentData } from "../redux/mcqSlice";

const MySpaceIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="lucide lucide-box flex-shrink-0 h-4 w-4"
  >
    <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path>
    <path d="m3.3 7 8.7 5 8.7-5"></path>
    <path d="M12 22V12"></path>
  </svg>
);

const MySpaceAdd = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6 mr-2"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" />
  </svg>
);

const HomePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Use translation hook
  const [showPopup, setShowPopup] = useState(false);
  const spacesFromStore = useSelector((state) => state.home?.spaces);

  // Optional callback when using `YouTubeInputField` with `noRedirect`
  const handleVideoSubmit = (videoId) => {
    message.info(t("homepage.videoSubmitted", { videoId }));
  };

  const dispatch = useDispatch();
  const [spaces, setSpacesLocal] = useState(spacesFromStore);
  const [selectedSpace, setSelectedSpace] = useState(null);

  const [loading, setLoading] = useState(spacesFromStore?.length === 0);

  const loadSpaces = async () => {
    // setLoading(true);
    try {
      const data = await fetchAllSpaces();
      setSpacesLocal(data);

      dispatch(setSpaces(data));
    } catch (error) {
      console.error(t("homepage.errorFetchingSpaces"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(
      setMcqComponentData({
        startScreen: true,
      })
    );
  }, []);

  useEffect(() => {
    loadSpaces();
  }, []);

  const createSpaceHandler = async () => {
    setLoading(true);
    try {
      let res = await createSpace({
        name: t("sidebar.untitledSpace"),
        description: t("sidebar.noDescription"),
      });

      if (res.error) {
        setShowPopup(true);
        return;
      }

      message.success(t("sidebar.spaceCreated"));

      loadSpaces();
    } catch (error) {
      console.error("Error:", error.response.data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="bg-black  h-[92vh] overflow-y-scroll ">
        <div className="flex pt-10 flex-col items-center justify-center bg-custom-main-gradient text-white px-4 sm:px-6 lg:px-10">
          {/* Main Heading */}
          <h1 className="text-center font-normal lg:text-4xl sm:text-3xl text-xl leading-relaxed mb-8">
            {t("homepage.mainHeading")}
          </h1>
          {/* Input Field */}
          <YouTubeInputField />
          {loading ? (
            <div className="w-full ">
              <SkeletonDashboard />
            </div>
          ) : (
            <>
              <div className="w-full max-w-7xl mb-12">
                <HistoryPage drawer count={4} />
              </div>
              {/* My Spaces Topics Section */}
              {spaces?.length > 0 && (
                <div className="w-full max-w-7xl mb-10">
                  <h1 className="text-xl font-bold mb-4">
                    {t("homepage.mySpaces")}
                  </h1>

                  <div
                    className={`grid grid-cols-1 sm:grid-cols-2 ${
                      spaces.length === 1
                        ? "lg:grid-cols-2"
                        : spaces.length === 0
                        ? "lg:grid-cols-1"
                        : "lg:grid-cols-3"
                    } gap-6`}
                  >
                    {spaces?.map((space) => {
                      return (
                        <div
                          key={space.id}
                          className="flex items-center justify-between px-2 py-1 cursor-pointer bg-customGray hover:bg-gray-900 border border-gray-700 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transform hover:-translate-y-1 transition duration-300"
                          onClick={() => navigate(`/space/${space.id}`)}
                        >
                          <div className="flex items-center">
                            <MySpaceIcon />
                            <div className="p-3">
                              <h3 className="text-sm sm:text-sm font-light">
                                {space.name}
                              </h3>
                            </div>
                          </div>

                          {/* Delete button with stopPropagation */}
                          <div
                            onClick={(e) => e.stopPropagation()} // Prevents the parent onClick from firing
                          >
                            <button onClick={() => setSelectedSpace(space)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="lucide lucide-trash h-4 w-4 hover:text-destructive hover:text-red-500 transition-all duration-200 hover:scale-105 flex-shrink-0"
                              >
                                <path d="M3 6h18"></path>
                                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                      );
                    })}

                    {selectedSpace && (
                      <DeleteSpaceModal
                        spaceId={selectedSpace?.id}
                        spaceName={selectedSpace?.name} // Pass the correct space name
                        refreshSpaces={loadSpaces}
                        isVisible={selectedSpace}
                        closeModel={() => setSelectedSpace(null)}
                      />
                    )}
                    <div
                      onClick={() => {
                        !loading && createSpaceHandler();
                      }}
                      className="flex items-center px-2 py-1 cursor-pointer bg-customGray hover:bg-gray-900 border border-gray-700 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transform hover:-translate-y-1 transition duration-300"
                    >
                      <MySpaceAdd />
                      {loading ? (
                        <Spinner />
                      ) : (
                        <div className="py-3 px-2">
                          <h3 className="text-sm sm:text-sm font-light">
                            {t("sidebar.addSpace")}
                          </h3>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/* Explore Topics Section */}
              <ExploreTopics />
              <div className="h-16" />
            </>
          )}
        </div>

        {showPopup && (
          <UpgradeModal
            message={""}
            onClose={() => {
              setShowPopup(false);
            }}
            visible={showPopup}
          />
        )}
      </div>
    </>
  );
};

export default HomePage;
