import React, { useEffect, useState } from "react";
import { Button, Progress, message, Slider, Divider } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { runThreadForQuizes } from "../apis/assistantApi";
import { saveUserAnswer } from "../apis/quizzesApi";
import { motion } from "framer-motion";
import Spinner from "../components/Spinner";
import "./style/QuizComponent.css";
import Lottie from "react-lottie";
import * as animationData from "../lottie/Animation - 1731992655315.json";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { setMcqComponentData } from "../redux/mcqSlice";
import { useTranslation } from "react-i18next";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
};

let abcd = ["A", "B", "C", "D"];

const QuizComponent = () => {
  const dispatch = useDispatch();
  const mcqSlice = useSelector((state) => state.mcqSlice?.mcqData);
  const { t } = useTranslation();

  const [quizzes, setQuizzes] = useState(mcqSlice?.quizzes || []);
  const [selectedAnswers, setSelectedAnswers] = useState(
    mcqSlice?.selectedAnswers || {}
  );
  const [loading, setLoading] = useState(false);
  const [quizRetake, setQuizRetake] = useState(false);
  const [confirmSetSubmitModel, setConfirmSetSubmitModel] = useState(false);

  const [showResults, setShowResults] = useState(
    mcqSlice?.showResults || false
  );
  const [showAnimation, setShowAnimation] = useState(false);
  const [visibleHints, setVisibleHints] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState(
    mcqSlice?.currentQuestion || 0
  );
  const [viewedAnswers, setViewedAnswers] = useState(
    mcqSlice?.viewedAnswers || {}
  ); // Tracks which questions have been viewed
  const [startScreen, setStartScreen] = useState(mcqSlice?.startScreen);
  const [questionCount, setQuestionCount] = useState(
    mcqSlice?.QuizComponent || 10
  ); // Slider value for questions

  const { videoId } = useParams();
  const transcript = useSelector(
    (state) => state.video?.transcript?.transcript
  );

  const assistants = useSelector((state) => state.assistant?.assistants);
  const quizBotAssistant = assistants?.find(
    (assistant) => assistant.type === "QUIZES"
  );

  const threads = useSelector((state) => state.assistant?.threads);
  const quizThread = threads?.find((thr) => thr.type === "QUIZES");

  const questionContainerHeight = "60vh"; // Fixed height for consistent layout

  const handleStartQuiz = (isBegin) => {
    if (isBegin) {
      loadQuizData();
    } else {
      loadQuizData(true);
    }

    setStartScreen(false);
  };

  const loadQuizData = async (isContinue) => {
    setLoading(true);

    let numb = isContinue ? 1 : questionCount;

    if (transcript) {
      try {
        const res = await runThreadForQuizes(
          quizThread?.threadId,
          videoId,
          quizBotAssistant?.assistantId,
          transcript,
          numb
        );
        if (res?.status === "completed" && !res.error) {
          setQuizzes(res?.mcqs || []);
          const initialAnswers = {};
          res.mcqs.forEach((_, index) => {
            initialAnswers[index] = _.userAnswer?.userAnswerIndex || null;
          });
          setSelectedAnswers(initialAnswers);
          setViewedAnswers({}); // Reset viewed answers state
          setShowResults(false);
          setCurrentQuestion(0);
        }
      } catch (error) {
        console.error("Error fetching quiz data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!mcqSlice?.quizzes) {
      loadQuizData(true);
    }
  }, []);

  useEffect(() => {
    dispatch(
      setMcqComponentData({
        quizzes,
        questionCount,
        viewedAnswers,
        selectedAnswers,
        showResults,
        startScreen,
        currentQuestion,
      })
    );
  }, [
    quizzes,
    showResults,
    questionCount,
    viewedAnswers,
    selectedAnswers,
    startScreen,
    currentQuestion,
  ]);

  const renderStartScreen = () => (
    <motion.div
      className="flex flex-col items-center h-[60vh] justify-center text-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-3xl font-bold mb-4">Quiz</h1>
      <p className="text-lg font-medium mb-2">{t("quiz.howManyQuestions")}</p>
      <p className="text-sm text-gray-400 mb-6">{t("quiz.note")}</p>

      <Slider
        min={5}
        max={50}
        defaultValue={questionCount}
        onChange={(value) => setQuestionCount(value)}
        tooltip={{ open: true }}
        trackStyle={{ backgroundColor: "pink", height: "8px" }} // Pink for selected range
        railStyle={{ backgroundColor: "gray", height: "8px" }} // Gray for unselected range
        className="w-2/3 mb-6"
      />

      <div className="w-full flex items-center justify-evenly">
        {quizzes?.length > 0 ? (
          <>
            <Button
              className="bg-purple-500 text-white px-8 py-3 rounded-full hover:bg-purple-600"
              onClick={() => {
                retryQuiz();
                handleStartQuiz(true);
              }}
            >
              {t("quiz.startNewQuiz")}
            </Button>
          </>
        ) : (
          <Button
            className="bg-purple-500 text-white px-8 py-3 rounded-full hover:bg-purple-600"
            onClick={() => handleStartQuiz(true)}
          >
            {t("quiz.startNewQuiz")}
          </Button>
        )}
      </div>
    </motion.div>
  );

  const handleAnswerClick = (questionIndex, key, hasViewedAnswer) => {
    if (hasViewedAnswer) {
      // Prevent changing the answer if "Show Answer" has been clicked
      message.warning(t("quiz.hasViewedAlert"));
      return;
    }
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionIndex]: key, // Allow updating the answer if "Show Answer" hasn't been clicked
    }));
  };
  const handleNext = () => {
    if (currentQuestion < quizzes.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const retryQuiz = () => {
    setCurrentQuestion(0);
    setSelectedAnswers({});
    setViewedAnswers({});
    setShowResults(false);
    setStartScreen(true);
    setQuizRetake(true);
  };

  const submitHandler = async () => {
    setLoading(true);
    try {
      await saveUserAnswer(videoId, selectedAnswers);
      message.success("Quiz submitted successfully!");
      setShowResults(true);
      setShowAnimation(true);

      setTimeout(() => {
        setShowAnimation(false);
      }, 4000);
    } catch (error) {
      console.error("Error submitting quiz:", error);
      message.error("Failed to submit quiz answers. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const showAnswerForQuestion = (questionIndex) => {
    setViewedAnswers((prev) => ({
      ...prev,
      [questionIndex]: true,
    }));
  };

  const toggleHint = (questionIndex) => {
    setVisibleHints((prev) => ({
      ...prev,
      [questionIndex]: !prev[questionIndex],
    }));
  };

  const renderQuestion = () => {
    if (quizzes.length === 0) return null;
    const quiz = quizzes[currentQuestion];

    // Check if any userAnswer is available for the entire quiz
    const isAnyUserAnswerAvailable = quizzes.some((q) => q.userAnswer);
    const hasViewedAnswer = viewedAnswers[currentQuestion]; // Check if the answer for this question has been viewed

    return (
      <motion.div
        key={currentQuestion}
        initial={{ x: 300, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -300, opacity: 0 }}
        transition={{
          type: "spring",
          stiffness: 60,
          damping: 15,
          duration: 0.5,
        }}
        className="w-full flex flex-col items-center  mb-10"
        style={{ height: questionContainerHeight }}
      >
        <div className="text-center mb-6">
          <p className="text-customPink font-semibold text-lg mb-8">
            {t("quiz.question")} {currentQuestion + 1} / {quizzes.length}
          </p>

          {/* Display the hint if visible */}
          {visibleHints[currentQuestion] && quiz.hint && (
            <p className="mb-4 text-sm text-green-400 animate-bounce">
              {quiz.hint || "No hint available."}
            </p>
          )}

          <p className="text-xl text-gray-200 font-bold">{quiz.question}</p>
        </div>

        <div className="flex flex-col space-y-2 w-full">
          {Object.entries(quiz.options).map(([key, value]) => {
            // Determine if this option is the user's answer
            const isUserAnswer = quiz?.userAnswer?.userAnswerIndex === key;
            // Determine if this option is the correct answer
            const isCorrectAnswer = quiz.correct_answer === value;

            return (
              <Button
                key={key}
                className={`flex justify-start items-center w-full px-4 py-6 rounded-lg font-semibold border-gray-700 ${
                  hasViewedAnswer || isAnyUserAnswerAvailable // Show correct/incorrect answers only if user has viewed the answer for this question or if a user answer exists globally
                    ? isCorrectAnswer
                      ? "bg-green-600 text-white" // Highlight correct answer in green
                      : isUserAnswer
                      ? "bg-red-600 text-white" // Highlight incorrect user answer in red
                      : "bg-customGray text-gray-300" // Default gray for unselected
                    : selectedAnswers[currentQuestion] === key
                    ? "bg-customPink text-black" // Highlight selected answer before viewing answer
                    : "bg-customGray text-gray-300"
                } hover:bg-customPink`}
                onClick={() =>
                  handleAnswerClick(
                    currentQuestion,
                    key,
                    hasViewedAnswer || isAnyUserAnswerAvailable
                  )
                } // Allow answer change only if no answer has been viewed
              >
                <h1 className="mr-2">{abcd[key]}.</h1>
                <h1 className="text-wrap text-left text-sm">{value}</h1>
              </Button>
            );
          })}

          {/* Show Answer and Hint buttons */}
          <div className="flex items-center justify-between">
           
            <Button
              type="link"
              onClick={() => toggleHint(currentQuestion)}
              className="w-[80px] text-xs text-customPink px-4 py-2 hover:text-green-500"
            >
              {visibleHints[currentQuestion]
                ? t("quiz.hideHint")
                : t("quiz.showHint")}
            </Button>

            {!hasViewedAnswer && (
              <Button
                type="link"
                onClick={() => showAnswerForQuestion(currentQuestion)}
                className="w-[80px] text-xs text-customPink px-4 py-2 hover:text-green-500"
              >
                {t("quiz.showMore")}
              </Button>
            )}

          </div>
        </div>
      </motion.div>
    );
  };

  const renderProgressBar = () => (
    <Progress
      percent={((currentQuestion + 1) / quizzes.length) * 100}
      showInfo={false}
      strokeColor="#F9CCC2"
      trailColor="#333"
      className="w-full max-w-3xl mb-6"
    />
  );

  const renderNavigationButtons = () => (
    <div className="flex justify-between w-full px-10 items-center absolute bottom-4 ">
      <Button
        onClick={currentQuestion === 0 ? () => {} : handleBack}
        // disabled={currentQuestion === 0}
        className="bg-gray-800 text-gray-300 px-6 py-3 rounded-full hover:bg-gray-700"
      >
        <ArrowLeftOutlined />
      </Button>
      {currentQuestion === quizzes.length - 1 ? (
        <Button
          onClick={submitHandler}
          className="bg-customPink text-black px-6 py-3 rounded-full hover:bg-customPink"
        >
          {t("quiz.submit")}
        </Button>
      ) : (
        <Button
          onClick={handleNext}
          className="bg-customPink text-black px-6 py-3 rounded-full hover:bg-customPink"
        >
          <ArrowRightOutlined />
        </Button>
      )}
    </div>
  );

  const calculateResults = () => {
    let correctCount = 0;
    quizzes.forEach((quiz, index) => {
      if (
        !viewedAnswers[index] &&
        selectedAnswers[index] &&
        quiz.options[selectedAnswers[index]] === quiz.correct_answer
      ) {
        correctCount++;
      }
    });
    return {
      correct: correctCount,
      wrong: quizzes.length - correctCount,
    };
  };

  const renderResults = () => {
    const { correct, wrong } = calculateResults();

    let percent = ((correct / quizzes.length) * 100)?.toFixed(0);
    return (
      <div className="flex flex-col justify-center items-center h-[60vh]  text-white text-center space-y-6">
        <div className="rounded-lg  border border-gray-700 p-20">
          <h2 className="w-full text-2xl font-bold text-green-500 ">
            🎉 {t("quiz.congratulations")}
          </h2>

          {showAnimation && (
            <div className="absolute top-0 left-0 right-0 flex items-center justify-center">
              <div className="">
                <Lottie options={defaultOptions} height="100%" width="100%" />
                <Lottie options={defaultOptions} height="100%" width="100%" />
              </div>

              <div className="">
                <Lottie options={defaultOptions} height="100%" width="100%" />
                <Lottie options={defaultOptions} height="100%" width="100%" />
              </div>

              <div className="">
                <Lottie options={defaultOptions} height="100%" width="100%" />
                <Lottie options={defaultOptions} height="100%" width="100%" />
              </div>
            </div>
          )}
          {/* 
          {showAnimation ? (
            <Lottie options={defaultOptions} height={400} width={400} />
          ) : (
            <></>
          )} */}

          <div className="flex justify-around mt-6 text-lg font-semibold">
            {t("quiz.yourScore")}: {correct}/{quizzes.length} ({percent}%)
            {/* <div className="text-green-400">
              <p>Correct</p>
              <p className="text-3xl">{correct}</p>
            </div>
            <div className="text-red-400">
              <p>Wrong</p>
              <p className="text-3xl">{wrong}</p>
            </div>
            <div className="text-yellow-400">
              <p>Total</p>
              <p className="text-3xl">{quizzes.length}</p>
            </div> */}
          </div>
          {correct === quizzes.length ? (
            <p className="text-lg text-green-400 mt-6 font-medium">
              {t("quiz.msg1")} 🚀
            </p>
          ) : correct > quizzes.length / 2 ? (
            <p className="text-lg text-yellow-400 mt-6 font-medium">
              {t("quiz.msg2")} ✨
            </p>
          ) : (
            <>
              <p className="text-lg text-red-400 mt-6 font-medium">
                {t("quiz.msg3")}
              </p>
            </>
          )}

          <div className="mt-10 w-full flex items-center justify-evenly">
            <Button
              className="p-4 m-4"
              onClick={() => {
                setViewedAnswers({})
                setSelectedAnswers({})
                setCurrentQuestion(0);
                setShowResults(false);
              }}
            >
              {t("quiz.retake")}
            </Button>
            <Button className="p-4 m-4" onClick={loadQuizData}>
              {t("quiz.showAnswers")}
            </Button>
          </div>

          <Button
            className="p-4 m-4"
            onClick={() => {
              retryQuiz();
              setStartScreen(true);
            }}
          >
            {t("quiz.startNewQuiz")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center bg-customGray h-full  py-1 relative">
      {loading ? (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      ) : showResults ? (
        renderResults()
      ) : (
        <>
          {startScreen ? (
            <>{renderStartScreen()}</>
          ) : (
            <>
              <div
                className="w-full flex flex-col items-center  px-4 rounded-lg"
                style={{ height: questionContainerHeight }}
              >
                {/* <div className="w-full flex items-center justify-end">
                <Button className="" onClick={retryQuiz}>
                  Re Try
                </Button>
                </div> */}
                {renderProgressBar()}
                {renderQuestion()}
                {renderNavigationButtons()}
              </div>

              <div className="flex items-center absolute bottom-20 ">
                {/* <Button
                  className="bg-white mr-2  text-black px-6 py-3 rounded-full hover:bg-customPink"
                  onClick={retryQuiz}
                >
                  Retake
                </Button> */}

                <Button
                  size="small"
                  onClick={() => {
                    // setConfirmSetSubmitModel(true);
                    submitHandler();
                  }}
                  className="bg-green-600  text-white px-6 py-3 rounded-full hover:bg-customPink"
                >
                  {t("quiz.submitQuiz")}
                </Button>

                {confirmSetSubmitModel && (
                  <ModelConfirm
                    submitHandler={submitHandler}
                    retryQuiz={retryQuiz}
                    onClose={() => setConfirmSetSubmitModel(false)}
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default QuizComponent;

function ModelConfirm({ onClose, submitHandler, retryQuiz }) {
  const { t } = useTranslation();

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-customGray bg-opacity-70 backdrop-blur-sm"
      onClick={onClose}
    >
      <div
        className="relative bg-[#171717] text-white p-6 rounded-lg max-w-xl w-full shadow-lg border border-gray-700"
        onClick={(e) => e.stopPropagation()} // Prevent click outside to close
        style={{
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
        }}
      >
        <button
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-300"
          onClick={onClose}
          aria-label="Close"
        >
          <svg
            aria-hidden="true"
            fill="none"
            focusable="false"
            height="1em"
            role="presentation"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            width="1em"
          >
            <path d="M18 6L6 18M6 6l12 12"></path>
          </svg>
        </button>

        <div className="flex items-center">
          <h3 className="text-xl font-semibold">
            {t("quiz.submitConfirmation")}
          </h3>
        </div>

        {/* <p className="text-gray-400 mb-6"></p> */}

        <div className="flex justify-end mt-6 space-x-4">
          <Button
            onClick={() => {
              onClose();
            }}
            className="bg-gray-700 border-none text-white rounded-full px-4 py-1 hover:bg-gray-600"
            style={{
              borderRadius: "20px",
              padding: "0.5rem 1.5rem",
              fontWeight: "500",
            }}
          >
            {t("quiz.continue")}
          </Button>

          <Button
            onClick={() => {
              onClose();
              retryQuiz();
            }}
            className="bg-white border-none text-black rounded-full px-4 py-1 hover:bg-gray-600"
            style={{
              borderRadius: "20px",
              padding: "0.5rem 1.5rem",
              fontWeight: "500",
            }}
          >
            {t("quiz.retake")}
          </Button>

          <Button
            onClick={() => {
              onClose();
              submitHandler();
            }}
            className="bg-customPink border-none text-customGray rounded-full px-4 py-1 hover:bg-red-700"
            style={{
              borderRadius: "20px",
              padding: "0.5rem 1.5rem",
              fontWeight: "500",
            }}
          >
            {t("quiz.submitQuiz")}
          </Button>
        </div>
      </div>
    </div>
  );
}
