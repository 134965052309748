import React, { useState, useEffect } from "react";
import { Tabs, Input, Button, message } from "antd";
import { fetchAllPrompts, savePromptToDb } from "../apis/promptAPI";

const types = [
  "SUMMARRY",
  "CHAPTERS",
  "FLASH_CARDS",
  "CHAT_BOT",
//   "SPACE_CHAT",
  "MCQ",
  "MAQ"
];

const PromptManager = () => {
  const [activeTab, setActiveTab] = useState(types[0]);
  const [prompts, setPrompts] = useState({});
  const [currentPrompt, setCurrentPrompt] = useState("");
  const [loading, setLoading] = useState(false);

  // Fetch all prompts
  const fetchPrompts = async () => {
    try {
      const response = await fetchAllPrompts();
      return response;
    } catch (error) {
      throw new Error("Failed to fetch prompts: " + error.message);
    }
  };

  // Save or update a prompt
  const savePrompt = async (type, prompt) => {
    try {
      const response = await savePromptToDb({ type, prompt });
      return response;
    } catch (error) {
      throw new Error("Failed to save prompt: " + error.message);
    }
  };

  // Fetch prompts and update state
  useEffect(() => {
    const loadPrompts = async () => {
      try {
        setLoading(true);
        const data = await fetchPrompts();
        console.log(data, "API Response"); // Debugging API response
        // Map the response correctly to the prompts state
        const formattedPrompts = data?.reduce((acc, prompt) => {
          acc[prompt.id] = prompt.prompt; // Use `id` as the key since `type` is not present
          return acc;
        }, {});
        setPrompts(formattedPrompts);
        setLoading(false);
      } catch (error) {
        message.error(error.message || "Failed to fetch prompts");
        setLoading(false);
      }
    };
    loadPrompts();
  }, []);

  // Update currentPrompt when the activeTab changes
  useEffect(() => {
    console.log(prompts, "Prompts State");
    setCurrentPrompt(prompts[activeTab] || "");
  }, [activeTab, prompts]);

  // Handle tab change
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  // Handle save or update prompt
  const handleSave = async () => {
    if (!currentPrompt) {
      message.error("Prompt cannot be empty");
      return;
    }
    try {
      setLoading(true);
      const result = await savePrompt(activeTab, currentPrompt);
      setPrompts((prev) => ({
        ...prev,
        [activeTab]: currentPrompt,
      }));
      message.success(result.message || "Prompt saved successfully");
      setLoading(false);
    } catch (error) {
      message.error(error.message || "Failed to save prompt");
      setLoading(false);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Prompt Manager</h1>
      <Tabs
        defaultActiveKey={types[0]}
        activeKey={activeTab}
        onChange={handleTabChange}
        type="card"
      >
        {types.map((type) => (
          <Tabs.TabPane tab={type} key={type}>
            <div className="p-4">
              {prompts[type] ? (
                <div>
                  <h2 className="text-lg font-semibold mb-2">
                    Existing Prompt:
                  </h2>
                  <p className="mb-4 text-gray-700 border p-3 rounded bg-gray-100">
                    {prompts[type]}
                  </p>
                  <h3 className="text-lg font-semibold mb-2">Edit Prompt:</h3>
                </div>
              ) : (
                <h3 className="text-lg font-semibold mb-2">
                  Create a New Prompt:
                </h3>
              )}
              <Input.TextArea
                value={currentPrompt}
                onChange={(e) => setCurrentPrompt(e.target.value)}
                rows={5}
                placeholder="Enter the prompt here..."
                className="mb-4"
              />
              <Button
                type="primary"
                onClick={handleSave}
                loading={loading}
              >
                {prompts[type] ? "Update Prompt" : "Create Prompt"}
              </Button>
            </div>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default PromptManager;