import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { message, Progress, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSummary, setTranscript } from "../redux/videoSlice";
import { fetchTranscript, fetchTextFromPdf } from "../apis/youtubeAPI";
import {
  addChatMessageToThread,
  attachMsgToSpace,
  clearRedux,
  createNewThread,
  getAllThreadsByVdId,
  runThread,
  runThreadForMaq,
} from "../apis/assistantApi";
import { getUserId } from "../utils/utils";
import Spinner from "./Spinner";
import WarningModel from "./delete/CustomCommonModel";
import { setMAQ } from "../redux/maqSlice";
import UpgradeModal from "./UpgradeModal";

const YouTubeInputField = ({ noRedirect, onSubmit, loading }) => {
  const { t } = useTranslation();
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [fetching, setFetching] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfProcessing, setPdfProcessing] = useState(false);
  const [fileName, setFileName] = useState("");
  const [modelOpen, setModelOpen] = useState(false);
  const pdfDocId = `${getUserId()}_${Date.now()}`;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { spaceId } = useParams();

  const assistants = useSelector((state) => state.assistant?.assistants);
  const summaryAsst = assistants?.find(
    (assistant) => assistant.type === "SUMMARY"
  );

  const chapterAsst = assistants?.find(
    (assistant) => assistant.type === "CHAPTERS"
  );

  const threads = useSelector((state) => state.assistant?.threads);
  let maqThread = threads?.find((thr) => thr.type === "CHAT_PROMPTS");
  let summThread = threads?.find((thr) => thr.type === "SUMMARY");

  const chatBotAssistant = assistants?.find(
    (assistant) => assistant.type === "CHAT_PROMPTS"
  );

  useEffect(() => {
    // If pdfFile is set, proceed with submission
    if (pdfFile) {
      handleSubmit();
    }
  }, [pdfFile]);

  const handleSubmit = async () => {
    if (!youtubeUrl && !pdfFile) {
      message.error("Please provide a YouTube URL or upload a PDF.");
      return;
    }

    setFetching(true);

    const videoId = youtubeUrl ? extractVideoId(youtubeUrl) : pdfDocId;
    let threadId = "";

    let result;
    const fetchMaq = async (transcript) => {
      try {
        // Start both calls without awaiting them
        const maqPromise = runThreadForMaq(
          maqThread?.threadId,
          videoId,
          chatBotAssistant?.assistantId,
          transcript
        );

        // const summaryPromise = runThread(
        //   summThread?.threadId,
        //   videoId,
        //   summaryAsst?.assistantId,
        //   transcript
        // );

        // Await each promise individually and handle the response as soon as each completes
        maqPromise
          .then((res) => {
            if (res?.status === "completed") {
              dispatch(setMAQ(res?.questions));
            }
          })
          .catch((error) => {
            console.error("Error generating MAQ:", error);
          });

        // summaryPromise
        //   .then((result) => {
        //     if (result?.status === "completed") {
        //       dispatch(setSummary(result?.summary));
        //     }
        //   })
        //   .catch((error) => {
        //     console.error("Error generating summary:", error);
        //   });
      } catch (error) {
        console.error("Error in fetchMaq:", error);
      }
    };

    if (pdfFile) {
      setPdfProcessing(true);
      result = await fetchTextFromPdf(videoId, threadId, pdfFile);

      // await createNewThread({
      //   type: "SUMMARY",
      //   videoId,
      //   transcript: result?.transcript,
      //   assistantIdSummary: summaryAsst?.assistantId,
      //   assistantIdChapter: chapterAsst?.assistantId,
      // });

      clearRedux(dispatch);

      // fetchMaq(result.transcript);

      dispatch(setTranscript(result));
      setFetching(false);
      setPdfFile(null);
      setPdfProcessing(false);
      setModelOpen(false);

      if (noRedirect) {
        onSubmit && onSubmit(videoId, { type: "PDF", ...result });
      } else {
        navigate(`/video/${videoId}?type=PDF`, {
          state: { threadId, fileName },
        });
        setModelOpen(false);
      }

      if (spaceId) {
        await attachMsgToSpace(spaceId, result?.transcript);
      }

      return;
    } else {
      result = await fetchTranscript(videoId);
      // fetchMaq(result.transcript);
    }

    if (result?.error && result?.type === "view_upgrade_plan") {
      setTimeout(() => {
        setFetching(false);
        setShowUpgradeModel(true);
      }, 1000);
      return;
    }

    if (result?.error || result?.type === "TRANSCRIPT_ERROR") {
      setTimeout(() => {
        setFetching(false);
        setModelOpen(true);
      }, 2000);
      return;
    }

    // await createNewThread({
    //   type: "SUMMARY",
    //   videoId,
    //   transcript: result?.transcript,
    //   assistantIdSummary: summaryAsst?.assistantId,
    //   assistantIdChapter: chapterAsst?.assistantId,
    // });

    clearRedux(dispatch);
    // await getAllThreadsByVdId(videoId, dispatch);

    dispatch(setTranscript(result));

    setYoutubeUrl("");
    setFetching(false);

    if (!result?.transcript?.includes("Error:")) {
      if (videoId) {
        if (noRedirect) {
          onSubmit && onSubmit(videoId);
        } else {
          navigate(`/video/${videoId}?type=YOUTUBE`, { state: { threadId } });
        }
      } else {
        message.error(t("youtubeInputField.invalidUrl"));
      }
    } else {
      message.warning(t("youtubeInputField.invalidCintent"));
    }

    if (spaceId) {
      await attachMsgToSpace(spaceId, result?.transcript);
    }
  };

  const extractVideoId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|\/u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  const handlePdfUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      message.error("No file selected.");
      return;
    }
    setPdfFile(file);
    setFileName(file?.name); // Store the original file name
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const [showUpgradeModel, setShowUpgradeModel] = useState(false);

  const handleUpgradeClick = () => {
    setShowUpgradeModel(false);
    navigate("/pricing");
  };

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval;

    if (fetching) {
      setProgress(0); // Start from 0%
      interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 90) return 90; // Cap progress at 90% while fetching
          return prev + Math.floor(Math.random() * 6) + 5; // Increment by 5-10%
        });
      }, 1000); // Update every 1 second
    } else if (!fetching && progress < 100) {
      setProgress(100); // Instantly complete when fetching becomes false
    }

    return () => clearInterval(interval); // Clean up on unmount
  }, [fetching]);

  const renderProgressBar = () => (
    <Progress
      percent={progress}
      showInfo={false}
      strokeColor="#F9CCC2"
      trailColor="#333"
      className="w-full max-w-3xl"
    />
  );

  return (
    <div className="w-full flex flex-col items-center  mb-8 ">
      <div className="relative w-full max-w-md lg:max-w-2xl flex items-center border border-gray-700 rounded-2xl bg-customGray px-4 py-2">
          <UpgradeModal
            message={"You have exhausted your limit. Please upgrade your plan."}
            onClose={() => {
              setShowUpgradeModel(false);
            }}
            visible={showUpgradeModel}
            onUpgradeClick={handleUpgradeClick}
          />

        {/* PDF Upload Icon */}
        <WarningModel
          onClose={() => setModelOpen(false)}
          title={t("youtubeInputField.invalidCintentTitle")}
          tryAgain={handleSubmit}
          content={t("youtubeInputField.invalidCintent")}
          isVisible={modelOpen}
        />
        <label className="cursor-pointer flex items-center justify-center mr-3">
          <input
            type="file"
            accept="application/pdf"
            onChange={handlePdfUpload}
            className="hidden"
            disabled={pdfProcessing}
          />
          <span
            className={`text-2xl ${
              pdfProcessing ? "text-gray-500" : "text-white"
            } font-semibold`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-paperclip h-5 w-5 text-primary/70"
            >
              <path d="m21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
            </svg>
          </span>
        </label>

        {/* Status message if PDF is being processed */}
        {/* {pdfProcessing && (
        <span className="text-sm text-gray-400 ml-2">
          <Spinner />
        </span>
      )} */}

        {/* YouTube URL Input */}
        <input
          className="w-full bg-transparent text-white placeholder-gray-400 focus:outline-none"
          placeholder={t("youtubeInputField.placeholder")}
          value={youtubeUrl}
          onChange={(e) => setYoutubeUrl(e.target.value)}
          onKeyDown={handleKeyPress}
          disabled={pdfProcessing}
        />

        {/* Submit Button */}
        <button
          disabled={fetching || loading || pdfProcessing}
          className={`${
            fetching || loading || pdfProcessing
              ? "bg-black border border-gray-700"
              : "bg-gray-950"
          } hover:bg-gray-800 text-white px-3 py-1 rounded-lg transition duration-300`}
          onClick={handleSubmit}
        >
          {loading || fetching || pdfProcessing ? <Spinner /> : "⌘K"}
        </button>
      </div>
      <div className="w-full max-w-md lg:max-w-2xl">
        {fetching && renderProgressBar()}
      </div>
    </div>
  );
};

export default YouTubeInputField;
